

/*        DPM           */



/*
 *
 *
 * SFONDO: f7f7f7
HEADER TEXT bianco
SCRITTE nere: 222222

verdino foodchain  - 48A7AF
footer: 171717

bollini vari - 327B81 15% transparency
*
*
* */


*{
    color: #222;

}

a{
    color: #222;
    text-decoration: none;
}


.product-basicinfo-text-h1{
  margin-top: 0px;
}

h1{
   margin-top: 40px;
   text-align:left;
   font-size: 16pt;
}

h2{
   margin-top: 40px;
   font-size: 14pt;
   text-align:left;
}

p{
  font-size: 12pt;
}

.header-section
{
   width:500px;
   max-width:100vw;
   height:500px;
   max-height:100vw;
}


.custom-dot-list-style
{
  margin-bottom:70px!important;
}
.custom-dot-list-style-invisible
{
  display:none;
}



.page-container{
  position:relative;
  max-width:100vw;
  width:500px;
  margin-left:auto;
  margin-right:auto;
  overflow-x:hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}




.page-container-desktop{
  max-height: 1154px;
  padding-top:10px;
}

.page-container-desktop::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Edge */
}

.page-body{
  background-color:#f7f7f7; /*#f7f7f7;*/
  position:relative;
  padding:20px;
  padding-top:30px;
  border-radius: 40px 40px 0px 0px;
  padding-bottom: 150px;
  margin-top: calc(min(460px, 100vw) - 60px);
  z-index:3000;
  min-height:50vh;
}



.product-basicinfo{
 display:flex;
 flex-direction:row;
 justify-content:pace-around;
}


.product-basicinfo-merchantimage{
  max-width: min(100px, 18vw);
  border-radius:9000px;
}


.product-basicinfo-merchant{
  margin-top:-5px;
  font-size:11pt;
}

.product-basicinfo-lotnumber{
  font-size:11pt;
  margin-top:3px;
}

.product-basicinfo-lotdate{
  font-size:11pt;
  margin-top:3px;
}

.product-basicinfo-texts{
  padding-left:20px;

}



.merchant-basicinfo{
 display:flex;
 flex-direction:row;
}


.merchant-basicinfo-merchantimage{
  max-width: min(100px, 18vw);
  border-radius:9000px;
}







/* SupplyChainScroller.css */
.supply-chain-scroller {
  width: 100%;
  overflow-x: visible; /* Hide the scrollbar */
  overflow-y: visible;
  cursor: grab; /* Indicate draggable area */
  padding: 10px 0;
  position: relative;
  display: flex;
  margin: -45px;
  padding: 45px;
  scrollbar-width: none;
  overflow-x: auto;  /* Allow horizontal scrolling */
  cursor: grab;      /* Visual cue for draggable content */
}

/* Prevent text selection while dragging */
.supply-chain-scroller:active {
  cursor: grabbing;
}


/* Hide scrollbar completely */
.supply-chain-scroller::-webkit-scrollbar {
  display: none;
}

.scroller-inner {
  display: inline-flex;
}


/* Optional scrollbar styling */
.supply-chain-scroller::-webkit-scrollbar {
  height: 8px;
}

.supply-chain-scroller::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}


.supply-chain-item {
  border: 2px solid #E8E8E8;
  flex-direction:column;
  display: inline-block;
  padding:5px;
  width: 140px;
  height: 200px;
  text-align: center;
  margin-right: 10px;
  overflow: hidden;
  border-radius:20px;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

}


.supply-chain-item-image {
  margin-top:20px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 5px;
}

.supply-chain-item-label {
  margin-top:20px;
  font-weight:bold;
  font-size:12pt;
  color: #333;
  max-height:35px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}

.supply-chain-item:last-child {
  margin-right: 0;
}




.supply-chain-item.selected {
  -webkit-box-shadow: 5px 5px 27px -4px rgba(0,0,0,0.3);
  box-shadow: 5px 5px 27px -4px rgba(0,0,0,0.3);
}








.button_download_scheda{
  background-color: #0066cc;
  position:relative;
  margin-right:auto;
  margin-left:auto;
  border-radius:8px;
  padding:8px;
  padding-left:15px;
  margin-top:10px;
  margin-bottom:10px;
  cursor:pointer;
}

.button_download_scheda span{
  background-color: #0066cc;
  color: #FFFFFF;
  font-weight:bold;
}

.button_download_scheda_disabled
{
  background-color: #d3d8de;
  color: #666666;
}

.button_download_scheda_disabled span
{
  background-color: #d3d8de;
  color: #666666;
}

.button_view_data{
  background-color: #FFFFFF;
  color: #00264d;
  position:relative;
  margin-right:auto;
  margin-left:auto;
  border:2px solid #00264d;
  border-radius:8px;
  font-weight:bold;
  padding:8px;
  padding-left:15px;
  margin-top:10px;
  margin-bottom:10px;
}


.button_view_data span{
  color: #00264d;
  font-weight:bold;
}



.footer-navigator{
 display:flex;
 flex-direction:row;
 justify-content:space-between;
  margin-top:10px;
  margin-bottom:20px;
  padding:10px;
  background-color: black;
  color:white;
  font-size:1.4em;
  border-radius:900px;
  max-width:min(100vw,500px);
  position: fixed;
  bottom: max(0px, calc(100vh - 1140px));;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  background-color: #333;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  z-index:3500;
}



.footer-navigator-option{
  border-radius:9000px;
  width:50px;
  height:50px;
  padding:20px;
}


.footer-navigator-option-label{
  text-align:center;
  margin-left: -100%;  /* trick to text-align even if text overflows */
  margin-right: -100%; /* trick to text-align even if text overflows */
  color:#888888;
  font-size:0.65em;
}

.footer-navigator-option-label.selected{
  color:#245f64;
  font-weight:bold;
}


.footer-navigator-option.selected{
  background-color:white;
}

.footer-navigator-option-icon{
  margin-bottom:-2px;
  margin-top:-3px;
}




.footer-navigator-option-icon img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:40px;
}


.footer-navigator > a{

  color:white;
}




@media only screen and (max-width: 350px) {
  .footer-navigator-option{
      width:40px;
      height:40px;
      padding:10px;
  }
  .footer-navigator-option-label{
    font-size:0.40em;
  }
  .footer-navigator-option-icon img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:30px;
  }
}


@media only screen and (max-width: 450px) {
  .footer-navigator-option{
      width:40px;
      height:40px;
      padding:10px;
  }
  .footer-navigator-option-label{
    font-size:0.40em;
  }
  .footer-navigator-option-icon img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:35px;
  }
}





.footer-bottom{
 display:flex;
 flex-direction:row;
 justify-content:space-between;
  margin-top:10px;
  margin-bottom:5px;
  padding:10px;
  font-size:1em;
  max-width:min(100vw,500px);
  position: fixed;
  bottom: 0;
  text-align: right;
  box-sizing: border-box;
  z-index:3500;
}



.horizontal-card {
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 20px;
  background-color: #f0f0f0;
  margin-top:20px;
  margin-bottom:20px;
  padding:20px;
}

.horizontal-card-image-outer {
  width:80px;
}

.horizontal-card-image-outer {
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  border-radius: 90px;
  background-color:#dae5e6;
  width:47px;
  height:47px;
  margin-right:30px;
}

.horizontal-card-image {
  width: 23px;
  height: 23px;
  object-fit: cover;
}

.horizontal-card-info {
  display: flex;
  flex-direction: column;
}

.horizontal-card-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom:10px;
}

.horizontal-card-prename {
  font-size: 14px;
  color:#777777;
  margin-bottom:8px;
}


.horizontal-card-address {
  font-size: 14px;
  color: #666;
}





.contact-image-outer {
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  border-radius: 90px;
  background-color:#dae5e6;
  width:47px;
  height:47px;
  min-width:47px;
  min-height:47px;
  margin-right:30px;
}

.contact-image {
  width: 23px;
  height: 23px;
  object-fit: contain;
}

.contact-info {
  display: flex;
  flex-direction: column;
}

.contact-name {
  font-size: 16px;
  font-weight: bold;
}

.contact-value {
  font-size: 14px;
  color: #666;
}







/* DocumentItem.css */
.document-item {
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 20px;
  background-color: #f0f0f0;
  margin-top:20px;
  margin-bottom:20px;
  padding:15px;
}


.document-item-icon-outer {
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  border-radius: 90px;
  background-color:#dae5e6;
  width:47px;
  height:47px;
  min-width:47px;
  min-height:47px;
  margin-right:30px;
}

.document-item-icon {
  width: 23px;
  height: 23px;
  object-fit: contain;
}



.document-item:hover {
  background-color: #e0e0e0;
}

.document-item-label {
  flex-grow: 1;
  font-size: 11pt;
  color: #333;
  text-align:left;
}

.document-item-caret {
  font-size: 12pt;
  color: #666;
  position:absolute;
  right:40px;
}






.map-outer{
  width:500px;
  max-width:100%;
  height:300px;
}








.process-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  -webkit-box-shadow: 5px 5px 27px -4px rgba(0,0,0,0.3);
  box-shadow: 5px 5px 27px -4px rgba(0,0,0,0.3);
  padding: 15px;
  border-radius: 20px;
  margin-top:30px;
}


.process-card-image-outer {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius:15px;
}

.process-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;  /* Ensures the image fits within the container without stretching */

}

.process-card-image {
  width: 100%;
  height: 200px;
}

.process-card-labels {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  padding: 8px;
  margin-top: -40px;
}

.process-card-date {
  font-size: 0.9em;
  color: #666;
  background-color:#fefefe;
  border-radius:30px;
  display:flex;
  align-content:center;
  flex-wrap:wrap;
  padding:10px;
  padding-left:20px;
  padding-right:20px;
  border:1px solid #F3F3F3;

}

.process-card-index {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  font-size: 0.9em;
  color: #333;
  background-color:#fefefe;
  border-radius:30px;
  border:1px solid #F3F3F3;
  padding:10px;
}

.process-card-index-icon {
  width:30px;
  background-color:#e0ebec;
  border-radius:900px;
  padding:5px;
}

.process-card-index-icon img{
  width:30px;
}

.process-card-index-text {
  height:100%;
  line-height:40px;
  padding-left:10px;
}

.process-card-content {
  padding: 16px;
}

.process-card-title {
  font-size: 1.2em;
  margin: 0 0 8px 0;
}

.process-card-description {
  font-size: 1em;
  color: #444;
}



.process-index-box-container
{
  display:flex;
  max-width:170px;
  padding:10px;
  margin:10px;
  align-items: center;
  flex-direction:row;
  justify-content:space-around;
  vertical-align:center;
  border-radius: 900px;
  -webkit-box-shadow: 5px 5px 27px -4px rgba(0,0,0,0.3);
  box-shadow: 5px 5px 27px -4px rgba(0,0,0,0.3);
}

.process-index-box-image-outer{
  border-radius: 900px;
  background-color: #e0ebec;
}

.process-index-box-image{
  width:40px;
}

.process-index-box{
  font-size:1.2em;
}


.title-header {
  padding: 1rem;
  background-color: #FFF;
  width:min(100vw, 470px);
  text-align: center;
  border-bottom: 1px solid #ddd;
  margin-bottom:30px;
  position:fixed;
  z-index:4000;
}

.title-header h1 {
  margin-top:10px;
}



.high-line-height{
 line-height:140%;
}

/* style.css */

.date-range-container {
  display: flex;
  justify-content:space-between;
  margin-top:30px;
  margin-bottom:30px;
}

.date-box {
  padding: 5px 20px;
  border-radius: 20px; /* Rounded borders */
  background-color: #f9f9f9;
  font-size: 13px;
  text-align: center;
  width:35%;
  -webkit-box-shadow: 5px 5px 27px -4px rgba(0,0,0,0.3);
  box-shadow: 5px 5px 27px -4px rgba(0,0,0,0.3);
}

.date-box-title{
  text-transform:uppercase;
  font-size:1em;
  margin-top:10px;
  margin-bottom:10px;
  color:#888888
}

.date-box-value{
  font-weight:bold;
  font-size:1.4em;
  margin-top:10px;
  margin-bottom:10px;
}






.image-header-back-button{
  position:fixed;
  z-index: 6000;
  top:25px;
  background-color:#fff;
  padding:15px;
  margin-left:15px;
  position:fixed;
  border: 1px solid #BBBBBB;
  border-radius:400px;
}


.arrow-back-icon{
  margin-right:10px;
}



.big-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top:20px;
}

.big-button {
    background-color: #48a7af; /* Button background color */
    color: #ffffff; /* Button text color */
    border: none;
    border-radius: 900px; /* Rounded corners */
    padding: 15px 30px; /* Button size */
    cursor: pointer;
    outline: none;
    width:100%;
    margin-top:10px;
    margin-bottom:10px;
    font-size:14pt;
    text-align:center;
    transition: background-color 0.3s ease;
}



.big-button:hover {
    background-color: #38979f; /* Darker shade for hover effect */
}



.big-button-outline {
    background-color: rgba(0,0,0,0); /* Button background color */
    color: #48a7af; /* Button text color */
    border: 2px solid #48a7af;
    border-radius: 900px; /* Rounded corners */
    padding: 15px 30px; /* Button size */
    cursor: pointer;
    outline: none;
    width:100%;
    margin-top:10px;
    margin-bottom:10px;
    font-size:14pt;
    text-align:center;
    transition: background-color 0.3s ease;
}






.process-list-container{
  margin-top:0px;
}


.process-navigation-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(min(100vw,500px) - 50px);
    height: 100%;
    margin-top:10px;
}

.not-so-big-button {
    background-color: #48a7af; /* Button background color */
    color: #ffffff; /* Button text color */
    border: none;
    border-radius: 900px; /* Rounded corners */
    padding: 12px 40px; /* Button size */
    cursor: pointer;
    outline: none;
    width:50%;
    margin-top:10px;
    margin-bottom:10px;
    font-size:12pt;
    text-align:center;
    transition: background-color 0.3s ease;
}


.not-so-big-button-disabled {
    background-color: #CCCCCC; /* Button background color */
    color: #ffffff; /* Button text color */
    border: none;
    border-radius: 900px; /* Rounded corners */
    padding: 12px 40px; /* Button size */
    cursor: pointer;
    outline: none;
    width:50%;
    margin-top:10px;
    margin-bottom:10px;
    font-size:12pt;
    text-align:center;
    transition: background-color 0.3s ease;
}


.not-so-big-button:hover {
    background-color: #38979f; /* Darker shade for hover effect */
}





.detail-type-label{
  text-transform: uppercase;
  font-size:9pt;
  color:#A0A0A0;

}




/* styles.css */

.extra-data {
  padding: 10px;
}

.extra-data-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
}

.extra-data-item .label {
  font-weight: bold;
}

.extra-data-item .value {
  font-style: italic;
}


.extra-data-link{
  color:#48A7AF;
}



.certification-icon-outer{
  width:115px;
  /*height:115px;*/
  border-radius:900px;
}
.certification-icon-outer img{
  width:115px;
}


.certification-name{
  text-align:center;
  text-wrap: pretty;
  width:80%;
  margin: auto;
}


.machinery-image{
  width:100%;
}











/* Style for loader container */
.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  width: min(100vw,500px);
  height: 100vh;
  /*background-color: rgba(255, 255, 255, 0.8); /* Optional background for effect */
  z-index: 0;
}


.loader-platform{
  margin-bottom:30px;
}


/* Style for the spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




.policy-popup {
  position: fixed;
  bottom: 0;
  width:min(100vw,500px);
  z-index: 6000; /* bazillion :) */
  background-color: #28878F;
  color: #fff;
  padding: 30px;
  font-size: 16px;
  transition: opacity 0.5s ease;
  opacity: 1; /* Start fully visible */
  box-sizing: border-box; /* Include padding in width calculation */

  left: 50%;
  transform: translateX(-50%);
}

.policy-popup.hidden {
  opacity: 0;
  pointer-events: none; /* Prevent interaction when hidden */
}

.policy-popup.visible {
  opacity: 1;
}



.badge-item {
    align-items: center;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 27px -4px #0000004d;
    box-shadow: 5px 5px 27px -4px #0000004d;
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    margin: 30px 10px 10px;
    padding: 5px;
    width: 250px;
    height: 80px;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */


}

.badge-item-content{
  margin-left:15px;
}

.badge-item-icon {margin-left:13px;}




.badge-item-title {
    font-size: 16px;
    /* padding: 5px; */
    margin-bottom: 5px;
    font-weight: 700;
    line-height: 17px;
}

.badge-item-value {
    display: flex;
    font-weight: 400;
    /* padding: 5px; */
}

.badge-item-verified {
    font-size: 14px;
    color: #656565;
}

.big-bullet-blue, .big-bullet-green{
    align-self: center;
    font-size: 18px;
    margin-right: 5px;
}


.big-bullet-blue{
  color:#2222BB;
}
.big-bullet-green{
  color:#449944;
}


.badge-scroller-infolink{
  text-align:right;
}
.badge-scroller-infolink a{
  text-align:right;
  color:#888888;
  text-decoration:underline;
}



.process-date-right{
 float:right;
 color:#a0a0a0;
}











.batch-box-container{
  max-height:180px;
  overflow-y: auto;
  box-sizing:border-box;
}



.batch-box-outer {
    position:relative;
    box-sizing:border-box;
    align-items: center;
    border-radius: 10px;
    background-color: #F0F0F0;
    /* justify-content: space-around; */
    margin: 0px;
    margin-bottom: 5px;
    box-sizing:border-box;
    border: 0px solid #CCCCCC;
    padding: 5px;
    width: 300px;
    width:98%;
    height:70px;
    padding-top:7px;
    padding-bottom:7px;
    transition: height 0.5s;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    overflow: hidden;

}

.batch-box {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    padding-bottom: 8px;
    box-sizing:border-box;
}

.batch-box-discovermore {
  cursor:pointer;
  position: absolute;
  right: 0px;
  margin-right: -440px;
  font-weight:bold;
  font-style: italic;
  color:#245f64;
}

.batch-box-discovermore:hover {
  color:#548f94;
}

.batch-box-discovermore.active {
  margin-right: 10px;
  transition: margin-right 0.5s ease-out;
}

.batch-box-discovermore.inactive {
  margin-right: -120px;
  transition: margin-right 0.5s ease-out;
}




.batch-box-outer.selected{
  background-color: #d7e5e6;
  height:100px;
}

.batch-box-type{
  margin-bottom:5px;
  font-size:11pt;

}


.batch-box-image{
  width:30px;
}

.batch-box-content{
  margin-left:15px;
  width:85%;

}

.batch-box-icon {margin-left:13px;}




.batch-box-title {
    font-size: 18px;
    /* padding: 5px; */
    margin-bottom: 5px;
    font-weight: 700;
    line-height: 17px;
}

.batch-box-value {
    display: flex;
    font-weight: 400;
    /* padding: 5px; */
}

.batch-box-verified {
    font-size: 16px;
    color: #656565;
}


























.stats-infolink{
  text-align:right;
}
.stats-infolink a{
  text-align:right;
  color:#888888;
  text-decoration:underline;
}


.stats{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  margin-top:20px;
  justify-content:space-between;
}





.stats-item{
  display:flex;
  flex-direction:row;
  justify-content:space-around;
  align-items: center;
  padding:5px;
  border-radius:10px;
  width:45%;
  flex-wrap:nowrap;
  margin-bottom:5px;
  margin-top:5px;
}

.stats-item-title{
  font-size:0.8em;
  margin: 3px 0px;
}
.stats-item-value{
  font-size:1.4em;
  font-weight:bold;
  margin: 3px 0px;
}





.stats-item.green{
  background-color:#D0F0C080;
}
.stats-item.green *{
  color:#99CE7F;
}



.stats-item.blue{
  background-color:#3ED2DB4D;
}
.stats-item.blue *{
  color:#2FAEB6;
}



.stats-item.yellow{
  background-color:#F0EBC080;
}
.stats-item.yellow *{
  color:#BEB54A;
}





.stats-item.purple{
  background-color:#C0D3F080;
}
.stats-item.purple *{
  color:#7F9ACE;
}


.stats-item-icon img{
  width:30px;
}




.mobileframe{
  pointer-events: none;
   position:fixed;
   left:50%;
   transform: translateX(-50%);
   z-index:20000;
   border-radius:90px
}

.mobileframe img{
   pointer-events: none;


}





.process-batches-container {
  display: flex;
  justify-content: space-between; /* Slight spacing between the boxes */
}

.process-batches-box {
  border-radius: 30px; /* Rounded borders */
  background-color: #f9f9f9;
  font-size: 16px;
  text-align: center;
  -webkit-box-shadow: 5px 5px 20px -4px rgba(0,0,0,0.3);
  box-shadow: 5px 5px 20px -4px rgba(0,0,0,0.3);
  padding:10px;
  padding-top:25px;
  width:40.5%

}


.process-batches-section {
  margin-bottom:20px;
  margin-top:20px;
}


.process-batches-icon {
  margin-bottom:20px;
  background-color:rgb(224, 235, 236);
  width:60px;
  height:60px;
  margin-left:auto;
  margin-right:auto;
  border-radius:900px;
  display:flex;
  align-items:center;
  justify-content:center;
}


.process-batches-icon img{
  width:30px;
}

.process-batches-title {
 text-transform: uppercase;
 font-size:10pt;
 font-weight:bold;
 color:#BBBBBB;
 margin-bottom:-12px;
}


.process-batches-name {
 font-size:14pt;
 font-weight:bold;
 color:black;
}


.process-batches-number {
 text-transform: uppercase;
 font-size:12pt;
 color:#999999;
}


.platform-logo{
   max-width:200px;
}


.foodchain-logo{
   max-width:130px;
   margin-top:120px;
}



.central-text{
  text-align:center;
}

.no-process-img{
  width:80%;
  margin-left:10%;
  margin-top:20px;
  margin-bottom:-20px;
}


.video_container {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video_embed{
   border: 0px solid white;
   width:100%;
   height:100%;
}



.react-multi-carousel-dot--active button
{
   background: #181818;
}


@media only screen and (min-width: 768px) {
  body{
    min-height: 100%;
    overflow-y:hidden;
    /*background: red;*/
    /*background: linear-gradient(6deg, rgba(56,52,130,1) 0px,  rgba(255,234,210,1) 7000px);*/
    background-image:url(img/background.jpg);
    background-size:center;
    background-size: 2000px;

  }



  .image-header-back-button{
    position:fixed;
    top:85px;
    background-color:#fff;
    padding:20px;
    margin-left:0px;
    left:calc(50vw - 230px);
    position:fixed;
    border: 1px solid #BBBBBB;
    border-radius:400px;
  }



  .page-container{
    margin-top:40px;
    border-radius:50px;
    height:1100px;
    max-height:min(1100px,100vh);
    min-height:min(1100px,100vh);
    background-color:#fff;

  }
  .loader-container{

    height:1100px;
    max-height:1100px;
    min-height:1100px;
    /*background-color:#fff;*/
  }

  .page-body{
    padding-bottom:200px;
  }

  header{
    border-radius: 50px 50px 0px 0px ;
    background-color:#fff;
  }

  header .carousel-container {
    border-radius: 50px 50px 0px 0px ;
  }

  header .leaflet-container{
    border-radius: 50px 50px 0px 0px ;
  }

  .header-section .carousel-container {
    border-radius: 50px 50px 0px 0px ;
  }

  .header-section .leaflet-container{
    border-radius: 50px 50px 0px 0px ;
  }


  .carousel-container {
    border-radius: 5px 5px 5px 5px ;
  }

  .leaflet-container{
    border-radius: 5px 5px 5px 5px ;
  }

  .leaflet-routing-container{
    display:none!important;
  }

  .leaflet-marker-draggable{
    display:none!important;
  }

  .footer-navigator{
    max-width: min(100vw, 480px);
  }
}





.page-body-nonavbar{
  margin-bottom: 10px;
  padding-bottom: 40px;

}


.page-body-loader{
  min-height:15000px;
  background-color:white;
}

.page-body-noheader{
  margin-top: 40px;
  border-radius:0px;
}



.page-body-nonavbar{
  margin-bottom: 10px;
  padding-bottom: 40px;

}





